<template>
    <section>
        <modal ref="modalAgregarAdmin" :titulo="titulo" icon="project" tamano="modal-xl" @guardar="guardar">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 mb-5">
                    <div class="col-8 custom-scroll overflow-auto" style="height:calc(50vh - 80px);">
                        <div class="row mx-0">
                            <div class="col-12 d-middle flex-column justify-center px-5">
                                <div class="position-relative">
                                    <slim-cropper ref="cropImagen" :options="slimOptions" class="border rounded-circle cr-pointer" style="height:120px;width:120px;background:#F8F9FF;" />
                                    <!-- <img class="position-absolute" src="/img/modales/camera.svg" style="top:30px;right:25px;max-width:100%;height:51px;" @click="getRef" /> -->
                                </div>
                                <p class="f-15 text-muted2 text-center my-2">
                                    Foto
                                </p>
                            </div>
                            <div class="row mx-0">
                                <div class="col-6">
                                    <p class="pl-3 text-muted2 f-14">Nombre</p>
                                    <ValidationProvider v-slot="{errors}" rules="required|max:25" name="nombre">
                                        <el-input v-model="model.nombre" class="w-100" maxlength="26" />
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                                <div class="col-6">
                                    <p class="pl-3 text-muted2 f-14">
                                        Número de celular
                                    </p>
                                    <ValidationProvider v-slot="{errors}" rules="required|numeric|max:18" name="teléfono">
                                        <el-input v-model="model.telefono" class="w-100" maxlength="18" />
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                                <div class="col-6 my-3">
                                    <p class="pl-3 text-muted2 f-14">
                                        Correo electrónico
                                    </p>
                                    <ValidationProvider v-slot="{errors}" rules="required|email|max:70" name="correo">
                                        <el-input v-model="model.correo" class="w-100" maxlength="71" />
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                                <div class="col-6 mt-4">
                                    <div class="col-6">
                                        <el-checkbox v-model="model.notificaciones" class="check-orange">
                                            Recibir notificaciones de correo electronico
                                        </el-checkbox>
                                    </div>
                                    <div class="col-6">
                                        <el-checkbox v-model="model.estado" class="check-orange">
                                            Activo
                                        </el-checkbox>
                                    </div>
                                </div>
                                <div class="col-6 my-3">
                                    <p class="pl-3 text-muted2 f-14">
                                        Contraseña
                                    </p>
                                    <ValidationProvider v-slot="{errors}" rules="required|max:200|" name="contraseña" vid="confirmation">
                                        <el-input v-model="model.password" class="w-100" type="password" maxlength="201" @change="cambioPassword" />
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                                <div class="col-6 my-3">
                                    <p class="pl-3 text-muted2 f-14">
                                        Confirmar contraseña
                                    </p>
                                    <ValidationProvider v-slot="{errors}" rules="required|max:200|confirmed:confirmation" name="confirmar contraseña">
                                        <el-input v-model="model.password_confirmation" class="w-100" type="password" maxlength="201" @change="cambioPassword" />
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                                <div class="col-6">
                                    <p class="pl-3 text-muted2 f-14">
                                        Cedis por defecto
                                    </p>
                                    <ValidationProvider v-slot="{errors}" rules="integer" name="cedis por defecto">
                                        <el-select v-model="model.id_cedis" placeholder="Selecciona" class="w-100">
                                            <el-option
                                            v-for="item in cedis"
                                            :key="item.id"
                                            :label="item.nombre"
                                            :value="item.id"
                                            >
                                                <span>{{ item.nombre }}</span>
                                                <i v-show="!item.estado" class="icon-eye-off" />
                                            </el-option>
                                        </el-select>
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                                <div class="col-6">
                                    <p class="pl-3 text-muted2 f-14">
                                        Moneda por defecto
                                    </p>
                                    <ValidationProvider v-slot="{errors}" rules="integer" name="moneda por defecto">
                                        <el-select v-model="model.idm_moneda" placeholder="Selecciona" class="w-100">
                                            <el-option
                                            v-for="item in monedas"
                                            :key="item.id"
                                            :label="`Moneda: ${item.nombre} - ${item.sigla}`"
                                            :value="item.id"
                                            />
                                        </el-select>
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 custom-scroll overflow-auto" style="height:calc(50vh - 80px);">
                        <div class="row mx-0 justify-center">
                            <div class="col-7">
                                <el-checkbox v-model="model.multicedis" class="ml-4">
                                    Admin multi-cedis
                                </el-checkbox>
                            </div>
                        </div>
                        <div class="row mx-0 my-3">
                            <div class="col-12">
                                <p class="text-muted2 f-15 text-center">
                                    Seleccione los cedis a administrar
                                </p>
                                <el-input v-model="buscar" placeholder="Buscar en la lista" class="w-100 br-20" prefix-icon="el-icon-search text-general" />
                            </div>
                        </div>
                        <p class="text-muted2 text-center">
                            Cedis Permitidos
                        </p>                        
                        <div class="row mx-0 justify-center my-3 ">
                            <div class="col-auto" />
                            <div class="col px-0">
                                <div class="row mx-0">
                                    <div v-for="(cedi, c) in cedis_permitidos.filter(el => !buscar
                                        || el.nombre.toLowerCase().includes(buscar.toLowerCase()))" :key="c" class="col-7 my-1"
                                    >
                                        <el-checkbox v-model="cedi.permitido"> 
                                            {{ cedi.nombre }}
                                            <i v-show="!cedi.estado" class="icon-eye-off" />
                                        </el-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto" />
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>
<script>
import Proyectos from '~/services/proyectos/proyectos'
import {mapGetters} from 'vuex'

import { extend } from 'vee-validate';
extend('espacios', {
    validate(value){
        var result = /^\S*$/u.test(value);
        return result;
    },
    params: ['length'],
    message: 'EL campo {_field_} no puede llevar espacios en blanco.'
});

export default {
    data(){
        return{
            modoEditar: false,
            model:{
                id: null,
                nombre: null,
                estado: false,
                telefono: null,
                correo: null,
                password: null,
                password_confirmation: null,
                id_proyecto: this.id_proyecto,
                multicedis: false,
                notificaciones: false,
                id_cedis: null,
                idm_moneda: null
            },
            cedis_permitidos: [],
            slimOptions: {
                label: 'Subir',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            cambioPass: false,
            titulo: 'Crear Administrador',
            buscar: '',
            cedis: []
        }
    },
    computed:{
        ...mapGetters({
            id_proyecto: 'proyectos/proyectos/id_proyecto',
            monedas: 'cedis/monedas',
        })
    },
    methods: {
        async toggle(info){
            this.limpiar()
            await this.getCedisProyecto()
            if(info != null){
                this.titulo= 'Editar Administrador'
                this.modoEditar = true
                this.model.id = info.id
                this.model.nombre = info.nombre
                this.model.estado = info.estado == 1 ? true : false
                this.model.telefono = info.telefono
                this.model.correo = info.correo
                this.model.multicedis = !!info.multicedis
                this.model.notificaciones = !!info.notificaciones
                this.model.id_cedis = info.id_cedis
                this.model.idm_moneda = info.idm_moneda
                this.model.password = '*********'
                this.model.password_confirmation = '*********'
                if (info.foto){
                    this.$refs.cropImagen.set_image(info.imagen)
                } else {
                    this.$refs.cropImagen.instanciaCrop.remove()
                }
            }else{
                this.$refs.cropImagen.instanciaCrop.remove()
            }
            await this.getCedisUserProyecto()
            this.$refs.modalAgregarAdmin.toggle();
        },
        async guardar(){
            try {
                let userCedis = this.cedis_permitidos.filter(d => d.permitido).map(({id}) => ({id}))
                const archivo = this.$refs.cropImagen.instanciaCrop.dataBase64.output
                if(!archivo){
                    this.notificacion('Mensaje', 'logo obligatorio', 'warning')
                    return false
                }
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                this.model.foto = archivo.image
                this.model.user_cedis = userCedis
                if(this.modoEditar){
                    this.model.cambioPass = this.cambioPass
                    if(!this.cambioPass){
                        this.model.password = null
                        this.model.password_confirmation = null
                    }
                    const {data} = await Proyectos.editarUsuarioAdmin(this.model)
                    this.notificacion('Mensaje', 'Usuario editado', 'success')
                }else{
                    const {data} = await Proyectos.crearUsuarioAdmin(this.model)
                    this.notificacion('Mensaje', 'Usuario creado', 'success')
                }
                this.$store.dispatch('proyectos/proyectos/action_detalle_proyecto', {id_proyecto: this.id_proyecto})
                this.$refs.modalAgregarAdmin.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        cambioPassword(){
            if(this.modoEditar){
                this.cambioPass= true
            }

        },
        async getCedisProyecto(){
            try {
                let params = {
                    id_proyecto: this.id_proyecto
                }
                const { data } =  await Proyectos.getCedisProyecto(params)
                this.cedis = data

            } catch (error){
                this.error_catch(error)
            }
        },
        async getCedisUserProyecto(){
            try {
                let params = {
                    id_proyecto: this.id_proyecto,
                    id_user: this.model.id
                }
                const { data } =  await Proyectos.getCedisUserProyecto(params)
                this.cedis_permitidos = data

            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.model={
                id: null,
                nombre: null,
                estado: null,
                telefono: null,
                correo: null,
                password: null,
                password_confirmation: null,
                id_proyecto: this.id_proyecto,
                multicedis: false,
                notificaciones: false,
                id_cedis: null,
                idm_moneda: null
            }
            this.cambioPass= false
            this.modoEditar = false
            this.$refs.validacion.reset()
            this.titulo= 'Crear Administrador'

        },
        getRef(){
            console.log(this.$refs.cropChat);
        }
    }
}
</script>
<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
</style>